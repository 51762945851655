@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Roobert, sans-serif;
}

/* Fonts */
@font-face {
    font-family: 'Gustavo';
    font-style: normal;
    font-weight: 700;
    src: local('Gustavo'), url('./fonts/Gustavo_Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gustavo';
    font-style: normal;
    font-weight: 500;
    src: local('Gustavo'), url('./fonts/Gustavo_Medium.woff') format('woff');
}

@font-face {
    font-family: 'Gustavo';
    font-style: normal;
    font-weight: 400;
    src: local('Gustavo'), url('./fonts/Gustavo_Regular.woff') format('woff');
}

@font-face {
    font-family: 'Gustavo';
    font-style: normal;
    font-weight: 300;
    src: local('Gustavo'), url('./fonts/Gustavo_Thin.woff') format('woff');
}

@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: normal;
    src: local('Roobert'), url('./fonts/RoobertRegular.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: italic;
    font-weight: normal;
    src: local('Roobert Italic'), url('./fonts/RoobertRegularItalic.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 300;
    src: local('Roobert'), url('./fonts/RoobertLight.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: italic;
    font-weight: 300;
    src: local('Roobert Light Italic'), url('./fonts/RoobertLightItalic.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 500;
    src: local('Roobert'), url('./fonts/RoobertMedium.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: italic;
    font-weight: 500;
    src: local('Roobert'), url('./fonts/RoobertMediumItalic.woff') format('woff');
}


@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 600;
    src: local('Roobert'), url('./fonts/RoobertSemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 700;
    src: local('Roobert'), url('./fonts/RoobertBold.woff') format('woff');
}

.text-173 {
    color: rgb(173, 173, 173)
}

.font-gustavo {
    font-family: 'Gustavo', 'Roobert', sans-serif !important;
}
.font-roobert {
    font-family: 'Roobert','Gustavo',  sans-serif !important;
}

.slide-in {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0f0f0f;
    transform-origin: bottom;
    z-index: 1000;
}

.slide-out {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0f0f0f;
    transform-origin: top;
    z-index: 1000;
}

.button-white-juicy {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.70);
    border-bottom: 1px solid rgba(255, 255, 255, 0.70);
    background: #FFF;
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px -1px rgba(0, 0, 0, 0.10), 0px -1px 4px 0px rgba(0, 0, 0, 0.05) inset;
    color: #1C1C1E;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.94px;
}

.button-white-juicy {
    border-radius: 6px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-family: Gustavo, Roobert, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.94px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;
    text-decoration: none;
    color: #36394A;
    background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.16) 100%), #FFFFFF;

    box-shadow: 0px 1px 2px rgba(164, 172, 185, 0.4), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    transition-property: box-shadow, background;
    border-radius: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.70);
    border-bottom: 1px solid rgba(255, 255, 255, 0.70);
    background: #FFF;
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px -1px rgba(0, 0, 0, 0.10), 0px -1px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

.button-white-juicy:hover {
    background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.16) 100%), #FFFFFF;
    box-shadow: 0px 1px 3px rgba(164, 172, 185, 0.7), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
}

.button-white-juicy:active {
    background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0) 100%), #FFFFFF;
}

.button-white-juicy:focus {
    background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.05) 100%), #FFFFFF;

    box-shadow: 0px 1px 2px rgba(48, 14, 124, 0.32), 0px 0px 0px 1px rgba(72, 1, 228, 0.76), 0px 0px 0px 3px rgba(126, 67, 255, 0.24);
}

.button-dark-main {
    color: #1C1C1E;
    font-family: Gustavo, Roobert, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFF;
    font-family: Gustavo, Roobert, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.94px;
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    border-radius: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: #1C1C1E;
    box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px -1px rgba(0, 0, 0, 0.10), 0px -1px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Gustavo, Roobert, sans-serif;
}

h2 {
    color: #1C1C1E;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 46.8px;
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
    }
}

@keyframes rotate {
    to {
        transform: scale(1.05) translateY(-44px) rotate(360deg) translateZ(0);
    }
}

.keysafe-container {
    min-height: 95vh;
    display: flex;
    font-family: 'Inter', Arial;
    justify-content: center;
    align-items: center;
    background-color: #020112;
    overflow: hidden;
    @media (min-width: 1500px) {
        padding-top: 4rem;
        min-height: initial;
    }
    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        inset: 40% -60% 0 -60%;
        background-image: radial-gradient(ellipse at bottom, #1D0559 0%, #020112 50%);
        opacity: .4;
    }
}

.cGriddParent,
.cGridContainer {
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
    overflow: hidden;
}

.cScene__content {
    width: 100%;
    height: 100%;
}

.cGridContainer {
    color: #fff;
    background-color: #222;
}

.cScene {
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%;
}

.cScene__container {
    position: relative;
    overflow: hidden;
    width: 100vh;
    height: 100vh;
    margin: 0 auto;
}

@media (orientation: portrait) {
    .cScene__container {
        width: 100%;
        height: 0;
        padding-top: 100%;
    }
}

.cScene__frame,
.c-scene__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cScene__overlay {
    z-index: 10;
    display: table;
}

.c-scene__body {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.cScene__cover {
    position: absolute;
    bottom: -1%;
    left: -50%;
    width: 200%;
    height: 101%;
}

@media (orientation: portrait) {
    .cScene {
        display: flex;
        align-items: center;
    }
}

.cScene__perspective {
    position: absolute;
    bottom: 0;
    left: -170%;
    width: 110%;
    height: 25%;
    perspective: 5vh;
    perspective-origin: 50% 50%;
    transform: scaleX(4) scaleY(4);
    transform-origin: 0% 100%;
}

.cScene__grid {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    transform: rotateX(60deg);
    transform-origin: 50% 100%;
}

.cScene__gridX,
.cScene__gridY {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    background-position: 50% 50%;
    background-repeat: repeat;
    background-size: 8% 20%;
}

.cScene__gridX {
    background-image: linear-gradient(to left, rgba(119, 74, 244, 0.2) 2px, transparent 2px);
}

.cScene__gridY {
    background-image: linear-gradient(to top, rgba(119, 74, 244, 0.2) 1px, transparent 1px);
}

.cScene__coverFrame {
    background: linear-gradient(rgba(34, 34, 34, 0) 0%, #774AF4 50%, rgba(34, 34, 34, 0) 75%, #222 100%);
}

.c-scene__perspective {
    perspective-origin: 50% 100%;
    will-change: perspective-origin;
    animation-duration: 3600ms;
    animation-iteration-count: 1;
    animation-name: intro-perspective;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.cScene__gridY {
    will-change: transform;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-name: grid-y;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.cScene__body {
    will-change: transform;
    animation-duration: 3600ms;
    animation-iteration-count: infinite;
    animation-name: hover;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes intro-perspective {
    0% {
        perspective-origin: 50% 100%;
    }

    100% {
        perspective-origin: 50% 50%;
    }
}

@keyframes grid-y {
    0% {
        transform: translateY(-20%);
    }

    100% {
        transform: translateY(0);
    }
}
